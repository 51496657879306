import React from "react";
import { injectIntl } from "gatsby-plugin-react-intl"

class CapterraTracker extends React.Component {

  componentDidMount(){
    let capterraScript = document.createElement('script'),
      capterra_vkey = 'a7c846bff6147715288d771e5c8189b6',
      capterra_vid = '2112143';

    capterraScript.id = 'capterraTracker';
    capterraScript.type = 'text/javascript';
    capterraScript.src = 'https://ct.capterra.com/capterra_tracker.js?vid=' + capterra_vid + '&vkey=' + capterra_vkey;

    if (!document.getElementById("capterraTracker")) {
      document.body.appendChild(capterraScript);
    }
  }

  render() {
    return null;
  }
}

export default injectIntl(CapterraTracker);
